import React, { FC } from "react";
import s from "./Impressum.module.scss";

export const Impressum: FC = () => {
  return (
    <div className={s.impressum}>
      <div className={s.title_wrap}>
        <h2 className={s.title}>IMPRESSUM</h2>
      </div>
      <div className={s.content}>
        <h2>Angaben gemäß §5 TMG:</h2>
        <p>GATE Eventmanagement &amp;
          Veranstaltungstechnik GmbH<br/>Salzufer
            13-14a<br/>Aufgang D, 1. OG<br/>10587
              Berlin</p>
        <h2>Vertreten durch:</h2>
        <p>Geschäftsführer: Marcus Zurdo
          Butz<br/>Prokuristin: Ira Zurdo
            Butz</p>
        <h2>Kontakt:</h2>
        <p>Tel: +49 (0)30 393 44 00<br/>Fax:
          +49 (0)30 345 093 78<br/>E-Mail:
            info@gate-av.de</p>
        <h2>Registereintrag:</h2>
        <p>Eintragung im Handelsregister<br/>Registergericht:
          Amtsgericht
          Berlin-Charlottenburg<br/>Registernummer:
            HRB 30845 B</p>
        <h2>Umsatzsteuer-ID:</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer
          gemäß §27a
          Umsatzsteuergesetz:<br/>DE136685938
        </p>
        <h2>Verantwortlich für den Inhalt
          nach §55 Abs. 2 RStV:</h2>
        <p>Marcus Zurdo&nbsp;Butz<br/>Salzufer
          13-14a<br/>Aufgang D, 1. OG<br/>10587
            Berlin</p>
        <h2>Quellenangaben für die
          verwendeten Bilder und
          Grafiken</h2>
        <p>© GATE | GATE Eventmanagement
          &amp; Veranstaltungstechnik GmbH<br/>©
            Sandra Wildemann | sandra
            wildemann fotogafie<br/>©
              andimartin | fotolia<br/>©
                hreniuca | fotolia<br/>© JFL
                  Photography | fotolia<br/>©
                    sonjanovak | fotolia<br/>© tailex
                      | fotolia<br/>© Olexandr
                        Taranukhin | shutterstock</p>
        <h2>Haftungsausschluss
          (Disclaimer)</h2>
        <h2>Haftung für Inhalte</h2>
        <p>Als Diensteanbieter sind wir&nbsp;gemäß
          §7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den
          allgemeinen&nbsp;Gesetzen
          verantwortlich. Nach §§8 bis 10
          TMG sind wir als Diensteanbieter
          jedoch nicht verpflichtet,&nbsp;übermittelte
          oder gespeicherte fremde
          Informationen zu überwachen oder
          nach Umständen zu&nbsp;forschen,
          die auf eine rechtswidrige
          Tätigkeit hinweisen.</p>
        <p>Verpflichtungen zur Entfernung
          oder&nbsp;Sperrung der Nutzung
          von Informationen nach den
          allgemeinen Gesetzen bleiben
          hiervon unberührt. Eine&nbsp;diesbezügliche
          Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer
          konkreten Rechtsverletzung&nbsp;möglich.
          Bei Bekanntwerden von
          entsprechenden
          Rechtsverletzungen werden wir
          diese Inhalte umgehend&nbsp;entfernen.</p>
        <h2>Haftung für Links</h2>
        <p>Unser Angebot enthält Links zu
          externen Webseiten&nbsp;Dritter,
          auf deren Inhalte wir keinen
          Einfluss haben. Deshalb können
          wir für diese fremden Inhalte&nbsp;auch
          keine Gewähr übernehmen. Für die
          Inhalte der verlinkten Seiten
          ist stets der jeweilige&nbsp;Anbieter
          oder Betreiber der Seiten
          verantwortlich. Die verlinkten
          Seiten wurden zum Zeitpunkt der
          Verlinkung auf&nbsp;mögliche
          Rechtsverstöße überprüft.
          Rechtswidrige Inhalte waren zum
          Zeitpunkt der&nbsp;Verlinkung
          nicht erkennbar.</p>
        <p>Eine permanente inhaltliche
          Kontrolle der verlinkten Seiten
          ist jedoch ohne&nbsp;konkrete
          Anhaltspunkte einer
          Rechtsverletzung nicht zumutbar.
          Bei Bekanntwerden von
          Rechtsverletzungen&nbsp;werden
          wir derartige Links umgehend
          entfernen.</p>
        <h2>Urheberrecht</h2>
        <p>Die durch die Seitenbetreiber&nbsp;erstellten
          Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen
          Urheberrecht. Die&nbsp;Vervielfältigung,
          Bearbeitung, Verbreitung und
          jede Art der Verwertung
          außerhalb der Grenzen des&nbsp;Urheberrechtes
          bedürfen der schriftlichen
          Zustimmung des jeweiligen Autors
          bzw. Erstellers. Downloads&nbsp;und
          Kopien dieser Seite sind nur für
          den privaten, nicht
          kommerziellen Gebrauch
          gestattet.</p>
        <p>Soweit&nbsp;die Inhalte auf
          dieser Seite nicht vom Betreiber
          erstellt wurden, werden die
          Urheberrechte Dritter beachtet.&nbsp;Insbesondere
          werden Inhalte Dritter als
          solche gekennzeichnet. Sollten
          Sie trotzdem auf eine&nbsp;Urheberrechtsverletzung
          aufmerksam werden, bitten wir um
          einen entsprechenden Hinweis.
          Bei Bekanntwerden&nbsp;von
          Rechtsverletzungen werden wir
          derartige Inhalte umgehend
          entfernen.</p>
        <p>Quelle:&nbsp;<a
          href="http://www.e-recht24.de/">http://www.e-recht24.de</a>
        </p>
      </div>
    </div>
)
}