// @ts-ignore
import DFJWLogo from './Logo DFJW.png';
import BMFSFJLogo from './Logo BMFSFJ[1].png';
import Deutsch_Flag from './Flagge_Deutschland.jpeg';
import France_Flag from './Flagge_Frankreich.jpeg';

export const logo = {
  dfjwLogo: DFJWLogo,
  bmfsfjLogo: BMFSFJLogo,
  france_flag: France_Flag,
  deutsch_flag: Deutsch_Flag,
};

