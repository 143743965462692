import axios, { /*AxiosRequestConfig*/ } from 'axios';
import { loadToken } from "../../utils/localStorage";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_WEBEX_BASE_URL,
});

instance.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${loadToken()}`;
  return config;
});