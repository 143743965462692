import React, { FC, useContext, useEffect } from 'react';
import s from './WebexMeeting1.module.scss';
import useWebexOAuth from '../../hooks/useWebexOAuth';
import WebexMeeting from '../Webex/WebexMeeting';
import Auth from '../Webex/LoginToWebex/OAuthLink';
import { saveToken } from '../../utils/localStorage';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../routes/Routes';

export const WebexMeeting1: FC = observer(() => {
  const webexToken = useWebexOAuth();
  const { store } = useContext(Context);
  // const REDIRECT_URI = useCurrentUri();
  const REDIRECT_URI = process.env.REACT_APP_WEBEX_REDIRECT_URI;
  const CLIENT_ID = process.env.REACT_APP_WEBEX_CLIENT_ID;
  const WEB_API_BASE_URL_AUTH = process.env.REACT_APP_WEBEX_BASE_URL_AUTH;
  const MEETING_1_ROOM_ID = process.env.REACT_APP_WEBEX_MEETING_1_ID;

  useEffect(() => {
    if (webexToken !== '') {
      saveToken(webexToken);
    }
  }, [webexToken]);

  useEffect(() => {
    store.fetchRooms();
  }, []);

  useEffect(() => {
    if (MEETING_1_ROOM_ID) {
      store.setCurrentRoom(MEETING_1_ROOM_ID);
    }
  }, [store.webexState.rooms]);

  return (
    <div className={s.webexMeeting1}>
      <div className={s.title_wrap}>
        <h2 className={s.title}>Break-out Raum I: Get-Together</h2>
      </div>

      <div className={s.webex_container}>
        {webexToken ? (
          <WebexMeeting webexToken={webexToken} />
        ) : (
          <Auth
            clientID={CLIENT_ID ? CLIENT_ID : ''}
            loginText="Login to Webex"
            redirectURI={REDIRECT_URI ? REDIRECT_URI : ''}
            webexAPIBaseURL={WEB_API_BASE_URL_AUTH ? WEB_API_BASE_URL_AUTH : ''}
          />
        )}
      </div>

      <section className={s.button_block}>
        <div className={s.button_container}>
          <div className={s.button_wrap}>
            <NavLink to={PATH.Livestream} className={s.button}>
              <span className={s.span}>Livestream</span>
            </NavLink>
          </div>
          <div className={s.button_wrap}>
            <NavLink to={PATH.WebexMeeting2} className={s.button}>
              <span className={s.span}>Webex Meeting 2</span>
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
});
