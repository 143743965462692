// import './index.css';
import App from './app/App';
import ReactDOM from 'react-dom';
import React, { createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/sass/base.scss';
import Store from './store/my_webex';

interface State {
  store: Store;
}

const store = new Store();
export const Context = createContext<State>({
  store,
});

ReactDOM.render(
  <React.StrictMode>
    <Context.Provider value={{ store }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Context.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
