import { instance } from "./http";
import { RoomType } from "../store/types";

export const webexAPI = {
  getRooms(){
    return instance.get<GetRoomsResponseType>('rooms')
  }
}

export type GetRoomsResponseType = {
  items: Array<RoomType>
}

export type ResponseType<D = {}> = {
  resultCode: number
  messages: Array<string>
  fieldsErrors: Array<string>
  data: D
}