import React, { FC } from "react";
import s from "./Datenschutz.module.scss";

export const Datenschutz: FC = () => {
  return (
    <div className={s.datenschutz}>
      <div className={s.title_wrap}>
        <h2 className={s.title}>Datenschutzerklärung</h2>
      </div>

      <div className={s.content}>
        <p>Stand: August 2020</p>
        <p>Die Betreiber dieser Seiten
          nehmen den Schutz Ihrer
          persönlichen Daten sehr ernst.
          Wir behandeln Ihre
          personenbezogenen Daten
          vertraulich und entsprechend der
          gesetzlichen
          Datenschutzvorschriften sowie
          dieser Datenschutzerklärung.</p>
        <p>Die Nutzung unserer Webseite ist
          in der Regel ohne Angabe
          personenbezogener Daten möglich.
          Soweit auf unseren Seiten
          personenbezogene Daten
          (beispielsweise Name, Anschrift
          oder E-Mail-Adressen) erhoben
          werden, erfolgt dies, soweit
          möglich, stets auf freiwilliger
          Basis. Diese Daten werden ohne
          Ihre ausdrückliche Zustimmung
          nicht an Dritte
          weitergegeben.</p>
        <p>Wir weisen darauf hin, dass die
          Datenübertragung im Internet
          (z.B. bei der Kommunikation per
          E-Mail) Sicherheitslücken
          aufweisen kann. Ein lückenloser
          Schutz der Daten vor dem Zugriff
          durch Dritte ist nicht
          möglich.</p>
        <p>Der Nutzung von im Rahmen der
          Impressumspflicht
          veröffentlichten Kontaktdaten
          durch Dritte zur Übersendung von
          nicht ausdrücklich angeforderter
          Werbung und
          Informationsmaterialien wird
          hiermit ausdrücklich
          widersprochen. Die Betreiber der
          Seiten behalten sich
          ausdrücklich rechtliche Schritte
          im Falle der unverlangten
          Zusendung von
          Werbeinformationen, etwa durch
          Spam-Mails, vor.</p>
        <h2>Hinweis zur verantwortlichen
          Stelle</h2>
        <p>Die verantwortliche Stelle für
          die Datenverarbeitung auf dieser
          Website ist:</p>
        <p>GATE Eventmanagement &amp;
          Veranstaltungstechnik GmbH<br/>Herr
            Marcus Zurdo Butz<br/>Salzufer
              13-14a<br/>Aufgang D, 1. OG<br/>10587
                Berlin</p>
        <p>Telefon: +49 (0)30 393 44 00<br/>E-Mail:
          contact@gate-av.de</p>
        <p>Verantwortliche Stelle ist die
          natürliche oder juristische
          Person, die allein oder
          gemeinsam mit anderen über die
          Zwecke und Mittel der
          Verarbeitung von
          personenbezogenen Daten (z.B.
          Namen, E-Mail-Adressen o. Ä.)
          entscheidet.</p>
        <h2>Datenschutzbeauftragter</h2>
        <p>– Gesetzlich vorgeschriebener
          Datenschutzbeauftragter</p>
        <p>Wir haben für unser Unternehmen
          einen Datenschutzbeauftragten
          bestellt.</p>
        <p>IITR Datenschutz GmbH<br/>Marienplatz
          2<br/>80331 München</p>
        <p>E-Mail:&nbsp;<a
          href="mailto:datenschutz@gate-av.de">datenschutz@gate-av.de</a><br/>WWW:
          https://www.iitr.de</p>
        <p>Tel: +49 89 1891 7360</p>
        <h2>Ihre Rechte</h2>
        <h3>Recht auf Bestätigung</h3>
        <p>Jede betroffene Person hat das
          vom Europäischen Richtlinien-
          und Verordnungsgeber eingeräumte
          Recht, von dem für die
          Verarbeitung Verantwortlichen
          eine Bestätigung darüber zu
          verlangen, ob sie betreffende
          personenbezogene Daten
          verarbeitet werden. Möchte eine
          betroffene Person dieses
          Bestätigungsrecht in Anspruch
          nehmen, kann sie sich hierzu
          jederzeit an einen Mitarbeiter
          des für die Verarbeitung
          Verantwortlichen wenden.</p>
        <h3>Recht auf Auskunft</h3>
        <p>Jede von der Verarbeitung
          personenbezogener Daten
          betroffene Person hat das vom
          Europäischen Richtlinien- und
          Verordnungsgeber gewährte Recht,
          jederzeit von dem für die
          Verarbeitung Verantwortlichen
          unentgeltliche Auskunft über die
          zu seiner Person gespeicherten
          personenbezogenen Daten und eine
          Kopie dieser Auskunft zu
          erhalten. Ferner hat der
          Europäische Richtlinien- und
          Verordnungsgeber der betroffenen
          Person Auskunft über folgende
          Informationen zugestanden:</p>
        <ul>
          <li>die Verarbeitungszwecke</li>
          <li>die Kategorien
            personenbezogener Daten, die
            verarbeitet werden
          </li>
          <li>die Empfänger oder
            Kategorien von Empfängern,
            gegenüber denen die
            personenbezogenen Daten
            offengelegt worden sind oder
            noch offengelegt werden,
            insbesondere bei Empfängern
            in Drittländern oder bei
            internationalen
            Organisationen
          </li>
          <li>falls möglich die geplante
            Dauer, für die die
            personenbezogenen Daten
            gespeichert werden, oder,
            falls dies nicht möglich
            ist, die Kriterien für die
            Festlegung dieser Dauer
          </li>
          <li>das Bestehen eines Rechts
            auf Berichtigung oder
            Löschung der sie
            betreffenden
            personenbezogenen Daten oder
            auf Einschränkung der
            Verarbeitung durch den
            Verantwortlichen oder eines
            Widerspruchsrechts gegen
            diese Verarbeitung
          </li>
          <li>das Bestehen eines
            Beschwerderechts bei einer
            Aufsichtsbehörde
          </li>
          <li>wenn die personenbezogenen
            Daten nicht bei der
            betroffenen Person erhoben
            werden: Alle verfügbaren
            Informationen über die
            Herkunft der Daten
          </li>
          <li>das Bestehen einer
            automatisierten
            Entscheidungsfindung
            einschließlich Profiling
            gemäß Artikel 22 Abs.1 und 4
            DS-GVO und — zumindest in
            diesen Fällen —
            aussagekräftige
            Informationen über die
            involvierte Logik sowie die
            Tragweite und die
            angestrebten Auswirkungen
            einer derartigen
            Verarbeitung für die
            betroffene Person
          </li>
        </ul>
        <p>Ferner steht der betroffenen
          Person ein Auskunftsrecht
          darüber zu, ob personenbezogene
          Daten an ein Drittland oder an
          eine internationale Organisation
          übermittelt wurden. Sofern dies
          der Fall ist, so steht der
          betroffenen Person im Übrigen
          das Recht zu, Auskunft über die
          geeigneten Garantien im
          Zusammenhang mit der
          Übermittlung zu erhalten.</p>
        <p>Möchte eine betroffene Person
          dieses Auskunftsrecht in
          Anspruch nehmen, kann sie sich
          hierzu jederzeit an einen
          Mitarbeiter des für die
          Verarbeitung Verantwortlichen
          wenden.</p>
        <h3>Recht auf Berichtigung</h3>
        <p>Jede von der Verarbeitung
          personenbezogener Daten
          betroffene Person hat das vom
          Europäischen Richtlinien- und
          Verordnungsgeber gewährte Recht,
          die unverzügliche Berichtigung
          sie betreffender unrichtiger
          personenbezogener Daten zu
          verlangen. Ferner steht der
          betroffenen Person das Recht zu,
          unter Berücksichtigung der
          Zwecke der Verarbeitung, die
          Vervollständigung
          unvollständiger
          personenbezogener Daten — auch
          mittels einer ergänzenden
          Erklärung — zu verlangen.</p>
        <p>Möchte eine betroffene Person
          dieses Berichtigungsrecht in
          Anspruch nehmen, kann sie sich
          hierzu jederzeit an einen
          Mitarbeiter des für die
          Verarbeitung Verantwortlichen
          wenden.</p>
        <h3>Recht auf Löschung (Recht auf
          Vergessen werden)</h3>
        <p>Jede von der Verarbeitung
          personenbezogener Daten
          betroffene Person hat das vom
          Europäischen Richtlinien- und
          Verordnungsgeber gewährte Recht,
          von dem Verantwortlichen zu
          verlangen, dass die sie
          betreffenden personenbezogenen
          Daten unverzüglich gelöscht
          werden, sofern einer der
          folgenden Gründe zutrifft und
          soweit die Verarbeitung nicht
          erforderlich ist:</p>
        <ul>
          <li>Die personenbezogenen Daten
            wurden für solche Zwecke
            erhoben oder auf sonstige
            Weise verarbeitet, für
            welche sie nicht mehr
            notwendig sind.
          </li>
          <li>Die betroffene Person
            widerruft ihre Einwilligung,
            auf die sich die
            Verarbeitung gemäß Art. 6
            Abs. 1 Buchstabe a DS-GVO
            oder Art. 9 Abs. 2 Buchstabe
            a DS-GVO stützte, und es
            fehlt an einer anderweitigen
            Rechtsgrundlage für die
            Verarbeitung.
          </li>
          <li>Die betroffene Person legt
            gemäß Art. 21 Abs. 1 DS-GVO
            Widerspruch gegen die
            Verarbeitung ein, und es
            liegen keine vorrangigen
            berechtigten Gründe für die
            Verarbeitung vor, oder die
            betroffene Person legt gemäß
            Art. 21 Abs. 2 DS-GVO
            Widerspruch gegen die
            Verarbeitung ein.
          </li>
          <li>Die personenbezogenen Daten
            wurden unrechtmäßig
            verarbeitet.
          </li>
          <li>Die Löschung der
            personenbezogenen Daten ist
            zur Erfüllung einer
            rechtlichen Verpflichtung
            nach dem Unionsrecht oder
            dem Recht der
            Mitgliedstaaten
            erforderlich, dem der
            Verantwortliche unterliegt.
          </li>
          <li>Die personenbezogenen Daten
            wurden in Bezug auf
            angebotene Dienste der
            Informationsgesellschaft
            gemäß Art. 8 Abs. 1 DS-GVO
            erhoben.
          </li>
        </ul>
        <p>Sofern einer der oben genannten
          Gründe zutrifft und eine
          betroffene Person die Löschung
          von personenbezogenen Daten, die
          bei der GATE Eventmanagement
          &amp; Veranstaltungstechnik GmbH
          gespeichert sind, veranlassen
          möchte, kann sie sich hierzu
          jederzeit an einen Mitarbeiter
          des für die Verarbeitung
          Verantwortlichen wenden. Der
          Mitarbeiter der GATE
          Eventmanagement &amp;
          Veranstaltungstechnik GmbH wird
          veranlassen, dass dem
          Löschverlangen unverzüglich
          nachgekommen wird.</p>
        <p>Wurden die personenbezogenen
          Daten von der GATE
          Eventmanagement &amp;
          Veranstaltungstechnik GmbH
          öffentlich gemacht und ist unser
          Unternehmen als Verantwortlicher
          gemäß Art. 17 Abs. 1 DS-GVO zur
          Löschung der personenbezogenen
          Daten verpflichtet, so trifft
          die GATE Eventmanagement &amp;
          Veranstaltungstechnik GmbH unter
          Berücksichtigung der verfügbaren
          Technologie und der
          Implementierungskosten
          angemessene Maßnahmen, auch
          technischer Art, um andere für
          die Datenverarbeitung
          Verantwortliche, welche die
          veröffentlichten
          personenbezogenen Daten
          verarbeiten, darüber in Kenntnis
          zu setzen, dass die betroffene
          Person von diesen anderen für
          die Datenverarbeitung
          Verantwortlichen die Löschung
          sämtlicher Links zu diesen
          personenbezogenen Daten oder von
          Kopien oder Replikationen dieser
          personenbezogenen Daten verlangt
          hat, soweit die Verarbeitung
          nicht erforderlich ist. Der
          Mitarbeiter der GATE
          Eventmanagement &amp;
          Veranstaltungstechnik GmbH wird
          im Einzelfall das Notwendige
          veranlassen.</p>
        <h3>Recht auf Einschränkung der
          Verarbeitung</h3>
        <p>Jede von der Verarbeitung
          personenbezogener Daten
          betroffene Person hat das vom
          Europäischen Richtlinien- und
          Verordnungsgeber gewährte Recht,
          von dem Verantwortlichen die
          Einschränkung der Verarbeitung
          zu verlangen, wenn eine der
          folgenden Voraussetzungen
          gegeben ist:</p>
        <ul>
          <li>Die Richtigkeit der
            personenbezogenen Daten wird
            von der betroffenen Person
            bestritten, und zwar für
            eine Dauer, die es dem
            Verantwortlichen ermöglicht,
            die Richtigkeit der
            personenbezogenen Daten zu
            überprüfen.
          </li>
          <li>Die Verarbeitung ist
            unrechtmäßig, die betroffene
            Person lehnt die Löschung
            der personenbezogenen Daten
            ab und verlangt stattdessen
            die Einschränkung der
            Nutzung der
            personenbezogenen Daten.
          </li>
          <li>Der Verantwortliche benötigt
            die personenbezogenen Daten
            für die Zwecke der
            Verarbeitung nicht länger,
            die betroffene Person
            benötigt sie jedoch zur
            Geltendmachung, Ausübung
            oder Verteidigung von
            Rechtsansprüchen.
          </li>
          <li>Die betroffene Person hat
            Widerspruch gegen die
            Verarbeitung gem. Art. 21
            Abs. 1 DS-GVO eingelegt und
            es steht noch nicht fest, ob
            die berechtigten Gründe des
            Verantwortlichen gegenüber
            denen der betroffenen Person
            überwiegen.
          </li>
        </ul>
        <p>Sofern eine der oben genannten
          Voraussetzungen gegeben ist und
          eine betroffene Person die
          Einschränkung von
          personenbezogenen Daten, die bei
          der GATE Eventmanagement &amp;
          Veranstaltungstechnik GmbH
          gespeichert sind, verlangen
          möchte, kann sie sich hierzu
          jederzeit an einen Mitarbeiter
          des für die Verarbeitung
          Verantwortlichen wenden. Der
          Mitarbeiter der GATE
          Eventmanagement &amp;
          Veranstaltungstechnik GmbH wird
          die Einschränkung der
          Verarbeitung veranlassen.</p>
        <h3>Recht auf
          Datenübertragbarkeit</h3>
        <p>Jede von der Verarbeitung
          personenbezogener Daten
          betroffene Person hat das vom
          Europäischen Richtlinien- und
          Verordnungsgeber gewährte Recht,
          die sie betreffenden
          personenbezogenen Daten, welche
          durch die betroffene Person
          einem Verantwortlichen
          bereitgestellt wurden, in einem
          strukturierten, gängigen und
          maschinenlesbaren Format zu
          erhalten. Sie hat außerdem das
          Recht, diese Daten einem anderen
          Verantwortlichen ohne
          Behinderung durch den
          Verantwortlichen, dem die
          personenbezogenen Daten
          bereitgestellt wurden, zu
          übermitteln, sofern die
          Verarbeitung auf der
          Einwilligung gemäß Art. 6 Abs. 1
          Buchstabe a DS-GVO oder Art. 9
          Abs. 2 Buchstabe a DS-GVO oder
          auf einem Vertrag gemäß Art. 6
          Abs. 1 Buchstabe b DS-GVO beruht
          und die Verarbeitung mithilfe
          automatisierter Verfahren
          erfolgt, sofern die Verarbeitung
          nicht für die Wahrnehmung einer
          Aufgabe erforderlich ist, die im
          öffentlichen Interesse liegt
          oder in Ausübung öffentlicher
          Gewalt erfolgt, welche dem
          Verantwortlichen übertragen
          wurde.</p>
        <p>Ferner hat die betroffene Person
          bei der Ausübung ihres Rechts
          auf Datenübertragbarkeit gemäß
          Art. 20 Abs. 1 DS-GVO das Recht,
          zu erwirken, dass die
          personenbezogenen Daten direkt
          von einem Verantwortlichen an
          einen anderen Verantwortlichen
          übermittelt werden, soweit dies
          technisch machbar ist und sofern
          hiervon nicht die Rechte und
          Freiheiten anderer Personen
          beeinträchtigt werden.</p>
        <p>Zur Geltendmachung des Rechts auf
          Datenübertragbarkeit kann sich
          die betroffene Person jederzeit
          an einen Mitarbeiter der GATE
          Eventmanagement &amp;
          Veranstaltungstechnik GmbH
          wenden.</p>
        <h3>Recht auf Widerspruch</h3>
        <p>Jede von der Verarbeitung
          personenbezogener Daten
          betroffene Person hat das vom
          Europäischen Richtlinien- und
          Verordnungsgeber gewährte Recht,
          aus Gründen, die sich aus ihrer
          besonderen Situation ergeben,
          jederzeit gegen die Verarbeitung
          sie betreffender
          personenbezogener Daten, die
          aufgrund von Art. 6 Abs. 1
          Buchstaben e oder f DS-GVO
          erfolgt, Widerspruch einzulegen.
          Dies gilt auch für ein auf diese
          Bestimmungen gestütztes
          Profiling.</p>
        <p>Die GATE Eventmanagement &amp;
          Veranstaltungstechnik GmbH
          verarbeitet die
          personenbezogenen Daten im Falle
          des Widerspruchs nicht mehr, es
          sei denn, wir können zwingende
          schutzwürdige Gründe für die
          Verarbeitung nachweisen, die den
          Interessen, Rechten und
          Freiheiten der betroffenen
          Person überwiegen, oder die
          Verarbeitung dient der
          Geltendmachung, Ausübung oder
          Verteidigung von
          Rechtsansprüchen.</p>
        <p>Verarbeitet die GATE
          Eventmanagement &amp;
          Veranstaltungstechnik GmbH
          personenbezogene Daten, um
          Direktwerbung zu betreiben, so
          hat die betroffene Person das
          Recht, jederzeit Widerspruch
          gegen die Verarbeitung der
          personenbezogenen Daten zum
          Zwecke derartiger Werbung
          einzulegen. Dies gilt auch für
          das Profiling, soweit es mit
          solcher Direktwerbung in
          Verbindung steht. Widerspricht
          die betroffene Person gegenüber
          der GATE Eventmanagement &amp;
          Veranstaltungstechnik GmbH der
          Verarbeitung für Zwecke der
          Direktwerbung, so wird die GATE
          Eventmanagement &amp;
          Veranstaltungstechnik GmbH die
          personenbezogenen Daten nicht
          mehr für diese Zwecke
          verarbeiten.</p>
        <p>Zudem hat die betroffene Person
          das Recht, aus Gründen, die sich
          aus ihrer besonderen Situation
          ergeben, gegen die sie
          betreffende Verarbeitung
          personenbezogener Daten, die bei
          der GATE Eventmanagement &amp;
          Veranstaltungstechnik GmbH zu
          wissenschaftlichen oder
          historischen Forschungszwecken
          oder zu statistischen Zwecken
          gemäß Art. 89 Abs. 1 DS-GVO
          erfolgen, Widerspruch
          einzulegen, es sei denn, eine
          solche Verarbeitung ist zur
          Erfüllung einer im öffentlichen
          Interesse liegenden Aufgabe
          erforderlich.</p>
        <p>Zur Ausübung des Rechts auf
          Widerspruch kann sich die
          betroffene Person direkt jeden
          Mitarbeiter der GATE
          Eventmanagement &amp;
          Veranstaltungstechnik GmbH oder
          einen anderen Mitarbeiter
          wenden. Der betroffenen Person
          steht es ferner frei, im
          Zusammenhang mit der Nutzung von
          Diensten der
          Informationsgesellschaft,
          ungeachtet der Richtlinie
          2002/58/EG, ihr
          Widerspruchsrecht mittels
          automatisierter Verfahren
          auszuüben, bei denen technische
          Spezifikationen verwendet
          werden.</p>
        <h3>Automatisierte Entscheidungen im
          Einzelfall einschließlich
          Profiling</h3>
        <p>Jede von der Verarbeitung
          personenbezogener Daten
          betroffene Person hat das vom
          Europäischen Richtlinien- und
          Verordnungsgeber gewährte Recht,
          nicht einer ausschließlich auf
          einer automatisierten
          Verarbeitung — einschließlich
          Profiling — beruhenden
          Entscheidung unterworfen zu
          werden, die ihr gegenüber
          rechtliche Wirkung entfaltet
          oder sie in ähnlicher Weise
          erheblich beeinträchtigt, sofern
          die Entscheidung (1) nicht für
          den Abschluss oder die Erfüllung
          eines Vertrags zwischen der
          betroffenen Person und dem
          Verantwortlichen erforderlich
          ist, oder (2) aufgrund von
          Rechtsvorschriften der Union
          oder der Mitgliedstaaten, denen
          der Verantwortliche unterliegt,
          zulässig ist und diese
          Rechtsvorschriften angemessene
          Maßnahmen zur Wahrung der Rechte
          und Freiheiten sowie der
          berechtigten Interessen der
          betroffenen Person enthalten
          oder (3) mit ausdrücklicher
          Einwilligung der betroffenen
          Person erfolgt.</p>
        <p>Ist die Entscheidung (1) für den
          Abschluss oder die Erfüllung
          eines Vertrags zwischen der
          betroffenen Person und dem
          Verantwortlichen erforderlich
          oder (2) erfolgt sie mit
          ausdrücklicher Einwilligung der
          betroffenen Person, trifft die
          GATE Eventmanagement &amp;
          Veranstaltungstechnik GmbH
          angemessene Maßnahmen, um die
          Rechte und Freiheiten sowie die
          berechtigten Interessen der
          betroffenen Person zu wahren,
          wozu mindestens das Recht auf
          Erwirkung des Eingreifens einer
          Person seitens des
          Verantwortlichen, auf Darlegung
          des eigenen Standpunkts und auf
          Anfechtung der Entscheidung
          gehört.</p>
        <p>Möchte die betroffene Person
          Rechte mit Bezug auf
          automatisierte Entscheidungen
          geltend machen, kann sie sich
          hierzu jederzeit an einen
          Mitarbeiter des für die
          Verarbeitung Verantwortlichen
          wenden.</p>
        <h3>Recht auf Widerruf einer
          datenschutzrechtlichen
          Einwilligung</h3>
        <p>Jede von der Verarbeitung
          personenbezogener Daten
          betroffene Person hat das vom
          Europäischen Richtlinien- und
          Verordnungsgeber gewährte Recht,
          eine Einwilligung zur
          Verarbeitung personenbezogener
          Daten jederzeit zu
          widerrufen.</p>
        <p>Möchte die betroffene Person ihr
          Recht auf Widerruf einer
          Einwilligung geltend machen,
          kann sie sich hierzu jederzeit
          an einen Mitarbeiter des für die
          Verarbeitung Verantwortlichen
          wenden.</p>
        <h2>SSL- bzw.
          TLS-Verschlüsselung</h2>
        <p>Diese Seite nutzt aus
          Sicherheitsgründen und zum
          Schutz der Übertragung
          vertraulicher Inhalte, wie zum
          Beispiel Bestellungen oder
          Anfragen, die Sie an uns als
          Seitenbetreiber senden, eine
          SSL-bzw. TLS-Verschlüsselung.
          Eine verschlüsselte Verbindung
          erkennen Sie daran, dass die
          Adresszeile des Browsers von
          “http://” auf “https://”
          wechselt und an dem
          Schloss-Symbol in Ihrer
          Browserzeile.</p>
        <p>Wenn die SSL- bzw.
          TLS-Verschlüsselung aktiviert
          ist, können die Daten, die Sie
          an uns übermitteln, nicht von
          Dritten mitgelesen werden.</p>
        <h2>Cookies</h2>
        <p>Die Internetseiten verwenden
          teilweise so genannte Cookies.
          Cookies richten auf Ihrem
          Rechner keinen Schaden an und
          enthalten keine Viren. Cookies
          dienen dazu, unser Angebot
          nutzerfreundlicher, effektiver
          und sicherer zu machen. Cookies
          sind kleine Textdateien, die auf
          Ihrem Rechner abgelegt werden
          und die Ihr Browser
          speichert.</p>
        <p>Die meisten der von uns
          verwendeten Cookies sind so
          genannte „Session-Cookies“. Sie
          werden nach Ende Ihres Besuchs
          automatisch gelöscht. Andere
          Cookies bleiben auf Ihrem
          Endgerät gespeichert, bis Sie
          diese löschen. Diese Cookies
          ermöglichen es uns, Ihren
          Browser beim nächsten Besuch
          wiederzuerkennen.</p>
        <p>Sie können Ihren Browser so
          einstellen, dass Sie über das
          Setzen von Cookies informiert
          werden und Cookies nur im
          Einzelfall erlauben, die Annahme
          von Cookies für bestimmte Fälle
          oder generell ausschließen sowie
          das automatische Löschen der
          Cookies beim Schließen des
          Browsers aktivieren. Bei der
          Deaktivierung von Cookies kann
          die Funktionalität dieser
          Website eingeschränkt sein.</p>
        <h2>Kontaktformular</h2>
        <p>Wenn Sie uns per Kontaktformular
          Anfragen zukommen lassen, werden
          Ihre Angaben aus dem
          Anfrageformular inklusive der
          von Ihnen dort angegebenen
          Kontaktdaten zwecks Bearbeitung
          der Anfrage und für den Fall von
          Anschlussfragen bei uns
          gespeichert. Diese Daten geben
          wir nicht ohne Ihre Einwilligung
          weiter.</p>
        <p>Die in diesem Zusammenhang
          anfallenden Daten löschen wir,
          nachdem die Speicherung nicht
          mehr erforderlich ist, oder
          schränken die Verarbeitung ein,
          falls gesetzliche
          Aufbewahrungspflichten
          bestehen</p>
        <h2>Widerspruch Werbe-Mails</h2>
        <p>Der Nutzung von im Rahmen der
          Impressumspflicht
          veröffentlichten Kontaktdaten
          zur Übersendung von nicht
          ausdrücklich angeforderter
          Werbung und
          Informationsmaterialien wird
          hiermit widersprochen. Die
          Betreiber der Seiten behalten
          sich ausdrücklich rechtliche
          Schritte im Falle der
          unverlangten Zusendung von
          Werbeinformationen, etwa durch
          Spam-E-Mails, vor.</p>
        <h2>Server-Log-Files</h2>
        <p>Der Provider der Seiten erhebt
          und speichert automatisch
          Informationen in so genannten
          Server-Log Files, die Ihr
          Browser automatisch an uns
          übermittelt. Dies sind:</p>
        <ul>
          <li>IP-Adresse</li>
          <li>Datum und Uhrzeit der
            Anfrage
          </li>
          <li>Zeitzonendifferenz zur
            Greenwich Mean Time (GMT)
          </li>
          <li>Inhalt der Anforderung
            (konkrete Seite)
          </li>
          <li>Betriebssystem und dessen
            Zugriffsstatus /
            HTTP-Statuscode
          </li>
          <li>Übertragene Datenmenge</li>
          <li>Website, von der die
            Anforderung kommt
          </li>
          <li>Browser, Sprache und Version
            der Browsersoftware
          </li>
        </ul>
        <p>Eine Zusammenführung dieser Daten
          mit anderen Datenquellen wird
          nicht vorgenommen.</p>
        <p>Grundlage für die
          Datenverarbeitung ist Art. 6
          Abs. 1 lit. f DSGVO. Demnach ist
          die Verarbeitung ist zur Wahrung
          der berechtigten Interessen des
          Verantwortlichen oder eines
          Dritten erforderlich.</p>
        <h2>Datenschutzerklärung für die
          Nutzung von Google
          Analytics</h2>
        <p>Diese Website nutzt Funktionen
          des Webanalysedienstes Google
          Analytics. Anbieter ist die
          Google Inc., 1600 Amphitheatre
          Parkway Mountain View, CA 94043,
          USA.</p>
        <p>Google Analytics verwendet sog.
          „Cookies“. Das sind Textdateien,
          die auf Ihrem Computer
          gespeichert werden und die eine
          Analyse der Benutzung der
          Website durch Sie ermöglichen.
          Die durch den Cookie erzeugten
          Informationen über Ihre
          Benutzung dieser Website werden
          in der Regel an einen Server von
          Google in den USA übertragen und
          dort gespeichert.</p>
        <p>Die Speicherung von
          Google-Analytics-Cookies erfolgt
          auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der
          Websitebetreiber hat ein
          berechtigtes Interesse an der
          Analyse des Nutzerverhaltens, um
          sowohl sein Webangebot als auch
          seine Werbung zu optimieren.</p>
        <h3>Browser Plugin</h3>
        <p>Sie können die Speicherung der
          Cookies durch eine entsprechende
          Einstellung Ihrer
          Browser-Software verhindern; wir
          weisen Sie jedoch darauf hin,
          dass Sie in diesem Fall
          gegebenenfalls nicht sämtliche
          Funktionen dieser Website
          vollumfänglich werden nutzen
          können. Sie können darüber
          hinaus die Erfassung der durch
          das Cookie erzeugten und auf
          Ihre Nutzung der Website
          bezogenen Daten (inkl. Ihrer
          IP-Adresse) an Google sowie die
          Verarbeitung dieser Daten durch
          Google verhindern, indem sie das
          unter dem folgenden Link
          verfügbare Browser-Plugin
          herunterladen und installieren:
          https://tools.google.com/dlpage/gaoptout?hl=de</p>
        <h3>Widerspruch gegen
          Datenerfassung</h3>
        <p>Sie können die Erfassung Ihrer
          Daten durch Google Analytics
          verhindern, indem Sie auf
          folgenden Link klicken&nbsp;<a
            href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>&nbsp;.
          Es wird ein Opt-Out-Cookie
          gesetzt, dass das Erfassung
          Ihrer Daten bei zukünftigen
          Besuchen dieser Website
          verhindert: Google Analytics
          deaktivieren.</p>
        <p>Mehr Informationen zum Umgang mit
          Nutzerdaten bei Google Analytics
          finden Sie in der
          Datenschutzerklärung von Google:
          https://support.google.com/analytics/answer/6004245?hl=de.</p>
        <h3>Auftragsdatenverarbeitung</h3>
        <p>Wir haben mit Google einen
          Vertrag zur
          Auftragsdatenverarbeitung
          abgeschlossen und setzen die
          strengen Vorgaben der deutschen
          Datenschutzbehörden bei der
          Nutzung von Google Analytics
          vollständig um.</p>
        <h3>IPAnonymisierung</h3>
        <p>Wir nutzen die Funktion
          „Aktivierung der
          IP-Anonymisierung“ auf dieser
          Webseite. Dadurch wird Ihre
          IP-Adresse von Google jedoch
          innerhalb von Mitgliedstaaten
          der Europäischen Union oder in
          anderen Vertragsstaaten des
          Abkommens über den Europäischen
          Wirtschaftsraum zuvor gekürzt.
          Nur in Ausnahmefällen wird die
          volle IP-Adresse an einen Server
          von Google in den USA übertragen
          und dort gekürzt. Im Auftrag des
          Betreibers dieser Website wird
          Google diese Informationen
          benutzen, um Ihre Nutzung der
          Website auszuwerten, um Reports
          über die Websiteaktivitäten
          zusammenzustellen und um weitere
          mit der Websitenutzung und der
          Internetnutzung verbundene
          Dienstleistungen gegenüber dem
          Websitebetreiber zu erbringen.
          Die im Rahmen von Google
          Analytics von Ihrem Browser
          übermittelte IP-Adresse wird
          nicht mit anderen Daten von
          Google zusammengeführt.</p>
        <h3>Demografische Merkmale bei
          Google Analytics</h3>
        <p>Diese Website nutzt die Funktion
          “demografische Merkmale” von
          Google Analytics. Dadurch können
          Berichte erstellt werden, die
          Aussagen zu Alter, Geschlecht
          und Interessen der
          Seitenbesucher enthalten. Diese
          Daten stammen aus
          interessenbezogener Werbung von
          Google sowie aus Besucherdaten
          von Drittanbietern. Diese Daten
          können keiner bestimmten Person
          zugeordnet werden. Sie können
          diese Funktion jederzeit über
          die Anzeigeneinstellungen in
          Ihrem Google-Konto deaktivieren
          oder die Erfassung Ihrer Daten
          durch Google Analytics wie im
          Punkt “Widerspruch gegen
          Datenerfassung” dargestellt
          generell untersagen.</p>
        <h2>Datenschutzerklärung für die
          Nutzung von Google Adsense</h2>
        <p>Diese Website benutzt Google
          AdSense, einen Dienst zum
          Einbinden von Werbeanzeigen der
          Google Inc. („Google“). Google
          AdSense verwendet sog.
          „Cookies“, Textdateien, die auf
          Ihrem Computer gespeichert
          werden und die eine Analyse der
          Benutzung der Website
          ermöglicht. Google AdSense
          verwendet auch so genannte Web
          Beacons (unsichtbare Grafiken).
          Durch diese Web Beacons können
          Informationen wie der
          Besucherverkehr auf diesen
          Seiten ausgewertet werden.</p>
        <p>Die durch Cookies und Web Beacons
          erzeugten Informationen über die
          Benutzung dieser Website
          (einschließlich Ihrer
          IP-Adresse) und Auslieferung von
          Werbeformaten werden an einen
          Server von Google in den USA
          übertragen und dort gespeichert.
          Diese Informationen können von
          Google an Vertragspartner von
          Google weiter gegeben werden.
          Google wird Ihre IP-Adresse
          jedoch nicht mit anderen von
          Ihnen gespeicherten Daten
          zusammenführen.</p>
        <p>Sie können die Installation der
          Cookies durch eine entsprechende
          Einstellung Ihrer Browser
          Software verhindern; wir weisen
          Sie jedoch darauf hin, dass Sie
          in diesem Fall gegebenenfalls
          nicht sämtliche Funktionen
          dieser Website voll umfänglich
          nutzen können. Durch die Nutzung
          dieser Website erklären Sie sich
          mit der Bearbeitung der über Sie
          erhobenen Daten durch Google in
          der zuvor beschriebenen Art und
          Weise und zu dem zuvor benannten
          Zweck einverstanden.</p>
        <h2>Datenschutzerklärung für die
          Nutzung von Google+</h2>
        <p>Unsere Seiten nutzen Funktionen
          von Google+. Anbieter ist die
          Google Inc., 1600 Amphitheatre
          Parkway Mountain View, CA 94043,
          USA.</p>
        <p>Erfassung und Weitergabe von
          Informationen: Mithilfe der
          Google+-Schaltfläche können Sie
          Informationen weltweit
          veröffentlichen. über die
          Google+-Schaltfläche erhalten
          Sie und andere Nutzer
          personalisierte Inhalte von
          Google und unseren Partnern.
          Google speichert sowohl die
          Information, dass Sie für einen
          Inhalt +1 gegeben haben, als
          auch Informationen über die
          Seite, die Sie beim Klicken auf
          +1 angesehen haben. Ihre +1
          können als Hinweise zusammen mit
          Ihrem Profilnamen und Ihrem Foto
          in Google-Diensten, wie etwa in
          Suchergebnissen oder in Ihrem
          Google-Profil, oder an anderen
          Stellen auf Websites und
          Anzeigen im Internet
          eingeblendet werden.</p>
        <p>Google zeichnet Informationen
          über Ihre +1-Aktivitäten auf, um
          die Google-Dienste für Sie und
          andere zu verbessern. Um die
          Google+-Schaltfläche verwenden
          zu können, benötigen Sie ein
          weltweit sichtbares,
          öffentliches Google-Profil, das
          zumindest den für das Profil
          gewählten Namen enthalten muss.
          Dieser Name wird in allen
          Google-Diensten verwendet. In
          manchen Fällen kann dieser Name
          auch einen anderen Namen
          ersetzen, den Sie beim Teilen
          von Inhalten über Ihr
          Google-Konto verwendet haben.
          Die Identität Ihres
          Google-Profils kann Nutzern
          angezeigt werden, die Ihre
          E-Mail-Adresse kennen oder über
          andere identifizierende
          Informationen von Ihnen
          verfügen.</p>
        <p>Verwendung der erfassten
          Informationen: Neben den oben
          erläuterten Verwendungszwecken
          werden die von Ihnen
          bereitgestellten Informationen
          gemäß den geltenden
          Google-Datenschutzbestimmungen
          genutzt. Google veröffentlicht
          möglicherweise zusammengefasste
          Statistiken über die
          +1-Aktivitäten der Nutzer bzw.
          gibt diese an Nutzer und Partner
          weiter, wie etwa Publisher,
          Inserenten oder verbundene
          Websites.</p>
        <h2>Datenschutzerklärung für die
          Einbindung von Google Maps</h2>
        <p>Auf dieser Webseite nutzen wir
          das Angebot von Google Maps.
          Dadurch können wir Ihnen
          interaktive Karten direkt in der
          Website anzeigen und ermöglichen
          Ihnen die komfortable Nutzung
          der Karten-Funktion.</p>
        <p>Durch den Besuch auf der Website
          erhält Google die Information,
          dass Sie die entsprechende
          Unterseite unserer Website
          aufgerufen haben. Zudem werden
          die unter dem Punkt
          „Server-Log-Files“ dieser
          Erklärung genannten Daten
          übermittelt. Dies erfolgt
          unabhängig davon, ob Google ein
          Nutzerkonto bereitstellt, über
          das Sie eingeloggt sind, oder ob
          kein Nutzerkonto besteht. Wenn
          Sie bei Google eingeloggt sind,
          werden Ihre Daten direkt Ihrem
          Konto zugeordnet. Wenn Sie die
          Zuordnung mit Ihrem Profil bei
          Google nicht wünschen, müssen
          Sie sich vor Aktivierung des
          Buttons ausloggen. Google
          speichert Ihre Daten als
          Nutzungsprofile und nutzt sie
          für Zwecke der Werbung,
          Marktforschung und/oder
          bedarfsgerechten Gestaltung
          seiner Website. Eine solche
          Auswertung erfolgt insbesondere
          (selbst für nicht eingeloggte
          Nutzer) zur Erbringung von
          bedarfsgerechter Werbung und um
          andere Nutzer des sozialen
          Netzwerks über Ihre Aktivitäten
          auf unserer Website zu
          informieren. Ihnen steht ein
          Widerspruchsrecht zu gegen die
          Bildung dieser Nutzerprofile,
          wobei Sie sich zur Ausübung
          dessen an Google richten
          müssen.</p>
        <p>Weitere Informationen zu Zweck
          und Umfang der Datenerhebung und
          ihrer Verarbeitung durch den
          Plug-in-Anbieter erhalten Sie in
          den Datenschutzerklärungen des
          Anbieters. Dort erhalten Sie
          auch weitere Informationen zu
          Ihren diesbezüglichen Rechten
          und Einstellungsmöglichkeiten
          zum Schutze Ihrer Privatsphäre:
          http://www.google.de/intl/de/policies/privacy.
          Google verarbeitet Ihre
          personenbezogenen Daten auch in
          den USA und hat sich dem EU-US
          Privacy Shield unterworfen,
          https://www.privacyshield.gov/EU-US-Framework.“</p>
        <h2>Datenschutzerklärung für die
          Nutzung von Facebook-Plugins
          (Like-Button)</h2>
        <p>Auf unseren Seiten sind Plugins
          des sozialen Netzwerks Facebook,
          Anbieter Facebook Inc., 1 Hacker
          Way, Menlo Park, California
          94025, USA, integriert. Die
          Facebook-Plugins erkennen Sie an
          dem Facebook-Logo oder dem
          „Like-Button“ („Gefällt mir“)
          auf unserer Seite. Eine
          übersicht über die
          Facebook-Plugins finden Sie
          hier:
          http://developers.facebook.com/docs/plugins/.</p>
        <p>Wenn Sie unsere Seiten besuchen,
          wird über das Plugin eine
          direkte Verbindung zwischen
          Ihrem Browser und dem
          Facebook-Server hergestellt.
          Facebook erhält dadurch die
          Information, dass Sie mit Ihrer
          IP-Adresse unsere Seite besucht
          haben. Wenn Sie den Facebook
          „Like-Button“ anklicken während
          Sie in Ihrem Facebook-Account
          eingeloggt sind, können Sie die
          Inhalte unserer Seiten auf Ihrem
          Facebook-Profil verlinken.
          Dadurch kann Facebook den Besuch
          unserer Seiten Ihrem
          Benutzerkonto zuordnen. Wir
          weisen darauf hin, dass wir als
          Anbieter der Seiten keine
          Kenntnis vom Inhalt der
          übermittelten Daten sowie deren
          Nutzung durch Facebook erhalten.
          Weitere Informationen hierzu
          finden Sie in der
          Datenschutzerklärung von
          Facebook unter http://dede.
          facebook.com/policy.php.</p>
        <p>Wenn Sie nicht wünschen, dass
          Facebook den Besuch unserer
          Seiten Ihrem
          Facebook-Nutzerkonto zuordnen
          kann, loggen Sie sich bitte aus
          Ihrem Facebook-Benutzerkonto
          aus.</p>
        <h2>Datenschutzerklärung für die
          Nutzung von YouTube</h2>
        <p>Unsere Webseite nutzt Plugins der
          von Google betriebenen Seite
          YouTube. Betreiber der Seiten
          ist die YouTube, LLC, 901 Cherry
          Ave., San Bruno, CA 94066, USA.
          Wenn Sie eine unserer mit einem
          YouTube-Plugin ausgestatteten
          Seiten besuchen, wird eine
          Verbindung zu den Servern von
          YouTube hergestellt. Dabei wird
          dem Youtube-Server mitgeteilt,
          welche unserer Seiten Sie
          besucht haben.</p>
        <p>Wenn Sie in Ihrem YouTube-Account
          eingeloggt sind ermöglichen Sie
          YouTube, Ihr Surfverhalten
          direkt Ihrem persönlichen Profil
          zuzuordnen. Dies können Sie
          verhindern, indem Sie sich aus
          Ihrem YouTube-Account
          ausloggen.</p>
        <p>Weitere Informationen zum Umgang
          von Nutzerdaten finden Sie in
          der Datenschutzerklärung von
          YouTube unter
          https://www.google.de/intl/de/policies/privacy</p>
        <h2>Datenschutzerklärung für die
          Nutzung von Xing</h2>
        <p>Unsere Webseite nutzt Funktionen
          des Netzwerks XING. Anbieter ist
          die XING AG, Dammtorstraße
          29-32, 20354 Hamburg,
          Deutschland. Bei jedem Abruf
          einer unserer Seiten, die
          Funktionen von Xing enthält,
          wird eine Verbindung zu Servern
          von Xing hergestellt. Eine
          Speicherung von
          personenbezogenen Daten erfolgt
          dabei nach unserer Kenntnis
          nicht. Insbesondere werden keine
          IP-Adressen gespeichert oder das
          Nutzungsverhalten
          ausgewertet.</p>
        <p>Weitere Information zum
          Datenschutz und dem Xing
          Share-Button finden Sie in der
          Datenschutzerklärung von Xing
          unter
          https://www.xing.com/app/share?op=data_protectio</p>
        <h2>Newsletterdaten</h2>
        <p>Wenn Sie den auf der Website
          angebotenen Newsletter beziehen
          möchten, benötigen wir von Ihnen
          eine E-Mail-Adresse sowie
          Informationen, welche uns die
          Überprüfung gestatten, dass Sie
          der Inhaber der angegebenen
          E-Mail-Adresse sind und mit dem
          Empfang des Newsletters
          einverstanden sind. Weitere
          Daten werden nicht bzw. nur auf
          freiwilliger Basis erhoben.
          Diese Daten verwenden wir
          ausschließlich für den Versand
          der angeforderten Informationen
          und geben diese nicht an Dritte
          weiter.</p>
        <p>Die Verarbeitung der in das
          Newsletteranmeldeformular
          eingegebenen Daten erfolgt
          ausschließlich auf Grundlage
          Ihrer Einwilligung (Art. 6 Abs.
          1 lit. a DSGVO). Die erteilte
          Einwilligung zur Speicherung der
          personenbezogenen Daten, der
          E-Mail-Adresse sowie deren
          Nutzung zum Versand des
          Newsletters (E-Mail-Adresse,
          Vorname, Nachname) können Sie
          jederzeit widerrufen, etwa über
          den „Austragen“-Link im
          Newsletter. Die Rechtmäßigkeit
          der bereits erfolgten
          Datenverarbeitungsvorgänge
          bleibt vom Widerruf
          unberührt.</p>
        <p>Die von Ihnen zum Zwecke des
          Newsletter-Bezugs bei uns
          hinterlegten Daten werden von
          uns bis zu Ihrer Austragung aus
          dem Newsletter gespeichert und
          nach der Abbestellung des
          Newsletters gelöscht. Daten, die
          zu anderen Zwecken bei uns
          gespeichert wurden (z.B.
          E-Mail-Adressen in den
          Kundenstammdaten) bleiben
          hiervon unberührt.</p>
        <h2>MailChimp</h2>
        <p>Diese Website nutzt die Dienste
          von MailChimp für den Versand
          von Newslettern. Anbieter ist
          die Rocket Science Group LLC,
          675 Ponce De Leon Ave NE, Suite
          5000, Atlanta, GA 30308,
          USA.</p>
        <p>MailChimp ist ein Dienst, mit dem
          u.a. der Versand von Newslettern
          organisiert und analysiert
          werden kann. Wenn Sie Daten zum
          Zwecke des Newsletterbezugs
          eingeben (z.B. E-Mail-Adresse),
          werden diese auf den Servern von
          MailChimp in den USA
          gespeichert.</p>
        <p>MailChimp verfügt über eine
          Zertifizierung nach dem
          “EU-US-Privacy-Shield”. Der
          “Privacy-Shield” ist ein
          Übereinkommen zwischen der
          Europäischen Union (EU) und den
          USA, das die Einhaltung
          europäischer
          Datenschutzstandards in den USA
          gewährleisten soll.</p>
        <p>Mit Hilfe von MailChimp können
          wir unsere Newsletterkampagnen
          analysieren. Wenn Sie eine mit
          MailChimp versandte E-Mail
          öffnen, verbindet sich eine in
          der E-Mail enthaltene Datei
          (sog. web-beacon) mit den
          Servern von MailChimp in den
          USA. So kann festgestellt
          werden, ob eine
          Newsletter-Nachricht geöffnet
          und welche Links ggf. angeklickt
          wurden. Außerdem werden
          technische Informationen erfasst
          (z.B. Zeitpunkt des Abrufs,
          IP-Adresse, Browsertyp und
          Betriebssystem). Diese
          Informationen können nicht dem
          jeweiligen Newsletter-Empfänger
          zugeordnet werden. Sie dienen
          ausschließlich der statistischen
          Analyse von Newsletterkampagnen.
          Die Ergebnisse dieser Analysen
          können genutzt werden, um
          künftige Newsletter besser an
          die Interessen der Empfänger
          anzupassen.</p>
        <p>Wenn Sie keine Analyse durch
          MailChimp wollen, müssen Sie den
          Newsletter abbestellen. Hierfür
          stellen wir in jeder
          Newsletternachricht einen
          entsprechenden Link zur
          Verfügung. Des Weiteren können
          Sie den Newsletter auch direkt
          unter folgendem Link&nbsp;<a
            href="https://gate-av.us12.list-manage.com/unsubscribe?u=9caeae895201a0adb7c7c4c4e&amp;id=1dedc2291b">https://gate-av.us12.list-manage.com/unsubscribe?u=9caeae895201a0adb7c7c4c4e&amp;id=1dedc2291b</a>&nbsp;und
          per E-Mail an&nbsp;<a
            href="mailto:contact@gate-av.de">contact@gate-av.de</a>&nbsp;abbestellen.
        </p>
        <p>Die Datenverarbeitung erfolgt auf
          Grundlage Ihrer Einwilligung
          (Art. 6 Abs. 1 lit. a DSGVO).
          Sie können diese Einwilligung
          jederzeit widerrufen, indem Sie
          den Newsletter abbestellen. Die
          Rechtmäßigkeit der bereits
          erfolgten
          Datenverarbeitungsvorgänge
          bleibt vom Widerruf
          unberührt.</p>
        <p>Die von Ihnen zum Zwecke des
          Newsletter-Bezugs bei uns
          hinterlegten Daten werden von
          uns bis zu Ihrer Austragung aus
          dem Newsletter gespeichert und
          nach der Abbestellung des
          Newsletters sowohl von unseren
          Servern als auch von den Servern
          von MailChimp gelöscht. Daten,
          die zu anderen Zwecken bei uns
          gespeichert wurden (z.B.
          E-Mail-Adressen für den
          Mitgliederbereich) bleiben
          hiervon unberührt.</p>
        <p>Näheres entnehmen Sie den
          Datenschutzbestimmungen von
          MailChimp unter:
          https://mailchimp.com/legal/terms/.</p>
        <h3>Abschluss eines
          Data-Processing-Agreements</h3>
        <p>Wir haben ein sog.
          „Data-Processing-Agreement“ mit
          MailChimp abgeschlossen, in dem
          wir MailChimp verpflichten, die
          Daten unserer Kunden zu schützen
          und sie nicht an Dritte
          weiterzugeben. Dieser Vertrag
          kann unter folgendem Link
          eingesehen werden:
          https://mailchimp.com/legal/forms/data-processing-agreement/sample-agreement/.</p>
      </div>
    </div>
)
}