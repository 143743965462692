import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { WebexMeetingWidget } from '@webex/widgets';
import './WebexMeeting.css';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
// This style import gives us all of the styling for the Meeting Widget needed
import '@webex/widgets/dist/webexWidgets.css';
// import WebexMeetingDestination from './WebexMeetingDestination';

function WebexMeeting({ webexToken }) {
  const { store } = useContext(Context);

  const [destination, setDestination] = useState(null);

  function handleSetDestination(destinationToSet) {
    setDestination(destinationToSet);
  }

  useEffect(() => {
    if (store.webexState.currentRoom) {
      handleSetDestination(store.webexState.currentRoom.id);
    }
  }, [store.webexState.currentRoom]);

  // The Meeting Widget only needs two things, an auth token and a meeting destination.
  // In this example, we are asking for the user to input the destination.
  // In your codebase, the destination could be already set.
  return (
    <div className="WebexMeeting">
      {destination ? (
        <WebexMeetingWidget accessToken={webexToken} meetingDestination={destination} />
      ) : (
        // <WebexMeetingDestination onSetDestination={handleSetDestination} />
        <div></div>
      )}
    </div>
  );
}

WebexMeeting.propTypes = {
  webexToken: PropTypes.string.isRequired,
};

export default observer(WebexMeeting);

