import React, { FC } from 'react';
import s from './Livestream.module.scss';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../routes/Routes';

export const Livestream: FC = () => {
  return (
    <div className={s.livestream}>
      <div className={s.title_wrap}>
        <h2 className={s.title}>Livestream</h2>
      </div>
      <div className={s.iframe_container}>
        <iframe
          className={s.iframe}
          id="sdn1997459270"
          title="3Q SDN"
          // width="640px"
          // height="360px"
          src="https://playout.3qsdn.com/f22b3d6a-4ee1-11ed-bf6a-3cecef385192"
          frameBorder="0"
          scrolling="no"
          allow="autoplay; picture-in-picture; encrypted-media; fullscreen"
          allowFullScreen
        />
      </div>

      <section className={s.button_block}>
        <div className={s.button_container}>
          <div className={s.button_wrap}>
            <NavLink to={PATH.Home} className={s.button}>
              <span className={s.span}>Break-out Raum I: Get-Together</span>
            </NavLink>
          </div>
          <div className={s.button_wrap}>
            <NavLink to={PATH.WebexMeeting2} className={s.button}>
              <span className={s.span}>Break-out Raum II: Follow-up</span>
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};
