import { makeAutoObservable } from 'mobx';
import { webexAPI } from '../api/api';
import { AxiosError } from 'axios';
import { DataType, RoomType } from './types';

export default class Store {
  webexState = {
    rooms: [] as Array<RoomType>,
    currentRoom: {} as RoomType,
  } as DataType;

  constructor() {
    makeAutoObservable(this);
  }

  setRooms(rooms: Array<RoomType>): void {
    this.webexState.rooms = rooms;
  }

  setCurrentRoom(roomId: string): void {
    const newRoom = this.webexState.rooms.find((room) => room.id === roomId);
    if (newRoom) {
      this.webexState.currentRoom = newRoom;
    }
  }

  // setCurrentRoom(roomName: string): void {
  //   const newRoom = this.webexState.rooms.find((room) => room.title === roomName);
  //   // this.webexState.currentRoom = newRoom ? newRoom : {};
  //   if (newRoom) {
  //     this.webexState.currentRoom = newRoom;
  //   }
  // }

  //requests

  fetchRooms(): any {
    webexAPI
      .getRooms()
      .then((res) => {
        this.setRooms(res.data.items);
      })
      .catch((err: AxiosError) => {
        console.log(err.message);
      });
  }
}