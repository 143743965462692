import React, { FC, useContext, useEffect } from "react";
import s from './WebexMeeting2.module.scss';
import { observer } from 'mobx-react-lite';
import useWebexOAuth from "../../hooks/useWebexOAuth";
import { Context } from "../../index";
import { saveToken } from "../../utils/localStorage";
import WebexMeeting from "../Webex/WebexMeeting";
import Auth from "../Webex/LoginToWebex/OAuthLink";
import { NavLink } from "react-router-dom";
import { PATH } from "../../routes/Routes";

export const WebexMeeting2: FC = observer(() => {
  const webexToken = useWebexOAuth();
  const { store } = useContext(Context);
  // const REDIRECT_URI = useCurrentUri();
  const REDIRECT_URI = process.env.REACT_APP_WEBEX_REDIRECT_URI2;
  const CLIENT_ID = process.env.REACT_APP_WEBEX_CLIENT_ID;
  const WEB_API_BASE_URL_AUTH = process.env.REACT_APP_WEBEX_BASE_URL_AUTH;
  const MEETING_2_ROOM_ID = process.env.REACT_APP_WEBEX_MEETING_2_ID;

  useEffect(() => {
    if (webexToken !== '') {
      saveToken(webexToken);
    }
  }, [webexToken]);

  useEffect(() => {
    store.fetchRooms();
  }, []);

  useEffect(() => {
    if (MEETING_2_ROOM_ID) {
      store.setCurrentRoom(MEETING_2_ROOM_ID);
    }
  }, [store.webexState.rooms]);

  return (
    <div className={s.webexMeeting2}>
      <div className={s.title_wrap}>
        <h2 className={s.title}>Break-out Raum II: Follow-up</h2>
      </div>
      <div className={s.webex_container}>
        {webexToken ? (
          <WebexMeeting webexToken={webexToken} />
        ) : (
          <Auth
            clientID={CLIENT_ID ? CLIENT_ID : ''}
            loginText="Login to Webex"
            redirectURI={REDIRECT_URI ? REDIRECT_URI : ''}
            webexAPIBaseURL={WEB_API_BASE_URL_AUTH ? WEB_API_BASE_URL_AUTH : ''}
          />
        )}
      </div>

      <section className={s.button_block}>
        <div className={s.button_container}>
          <div className={s.button_wrap}>
            <NavLink to={PATH.Livestream} className={s.button}>
              <span className={s.span}>Livestream</span>
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
});

// import React, { FC, useContext, useEffect } from "react";
// import s from './WebexMeeting2.module.scss';
// import { observer } from 'mobx-react-lite';
// import useWebexOAuth from "../../hooks/useWebexOAuth";
// import { Context } from "../../index";
// import { saveToken } from "../../utils/localStorage";
// import WebexMeeting from "../Webex/WebexMeeting";
// import Auth from "../Webex/LoginToWebex/OAuthLink";
//
// export const WebexMeeting2: FC = observer(() => {
//   const webexToken = useWebexOAuth();
//   const { store } = useContext(Context);
//   // const REDIRECT_URI = useCurrentUri();
//   const REDIRECT_URI = process.env.REACT_APP_WEBEX_REDIRECT_URI2;
//   const CLIENT_ID = process.env.REACT_APP_WEBEX_CLIENT_ID;
//   const WEB_API_BASE_URL_AUTH = process.env.REACT_APP_WEBEX_BASE_URL_AUTH;
//   const MEETING_2_ROOM_ID = process.env.REACT_APP_WEBEX_MEETING_2_ID;
//
//   useEffect(() => {
//     if (webexToken !== '') {
//       saveToken(webexToken);
//     }
//   }, [webexToken]);
//
//   useEffect(() => {
//     store.fetchRooms();
//   }, []);
//
//   useEffect(() => {
//     if (MEETING_2_ROOM_ID) {
//       store.setCurrentRoom(MEETING_2_ROOM_ID);
//     }
//   }, [store.webexState.rooms]);
//
//   return (
//     <div className={s.webexMeeting2}>
//       <h2 className={s.title}>{store.webexState.currentRoom.title}</h2>
//       <div className={s.webex_container}>
//         {webexToken ? (
//           <WebexMeeting webexToken={webexToken} />
//         ) : (
//           <Auth
//             clientID={CLIENT_ID ? CLIENT_ID : ''}
//             loginText="Login to Webex"
//             redirectURI={REDIRECT_URI ? REDIRECT_URI : ''}
//             webexAPIBaseURL={WEB_API_BASE_URL_AUTH ? WEB_API_BASE_URL_AUTH : ''}
//           />
//         )}
//       </div>
//     </div>
//   );
// });
