import './App.scss';
import { FC } from 'react';
import { Header } from '../pages/Main/Header/Header';
import { Footer } from '../pages/Main/Footer/Footer';
import { RoutesComponent } from '../routes/Routes';
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { Impressum } from "../pages/Main/Footer/Impressum/Impressum";
import { Datenschutz } from "../pages/Main/Footer/Datenschutz/Datenschutz";

const App: FC = () => {
  const location = useLocation().pathname;

  switch (location) {
    case '/impressum/':
      return <Impressum />;
    case '/datenschutz/':
      return <Datenschutz />;
  }

  return (
    <div className="App">
      <Header />
      <Footer />
      <RoutesComponent />
    </div>
  );
};

export default observer(App);
