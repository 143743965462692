/* eslint-disable */
/**
 * "loadToken" is used to get the token from local storage.
 * The function takes nothing and returns a token.
 * */
export const loadToken = () => {
  try {
    const tokenJSON = localStorage.getItem('webex_token');
    if (tokenJSON === null) {
      return undefined;
    }
    return JSON.parse(tokenJSON);
  } catch (err) {
    return undefined;
  }
};

/**
 * "saveToken" is used to save the token to local storage.
 * The function takes a token and returns nothing.
 * @param {string} token - token
 * */
export const saveToken = (token: string) => {
  try {
    const tokenJSON = JSON.stringify(token);
    localStorage.setItem('webex_token', tokenJSON);
  } catch {
    // ignore write errors
  }
};