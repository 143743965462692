import React, { FC } from 'react';
import s from './Footer.module.scss';
import { NavLink } from 'react-router-dom';
import { PATH } from "../../../routes/Routes";

export const Footer: FC = () => {
  return (
    <footer className={s.footer}>
      <nav className={s.nav}>
        <ul className={s.list}>
          <li className={s.item}>
            <NavLink className={s.link} to={PATH.Impressum}>
              <span className={s.span}>Impressum</span>
            </NavLink>
          </li>

          <li className={s.item}>
            <NavLink className={s.link} to={''}>
              <span className={s.span_copyright}>|</span>
            </NavLink>
          </li>

          <li className={s.item}>
            <NavLink className={s.link} to={PATH.Datenschutz}>
              <span className={s.span}>Datenschutz</span>
            </NavLink>
          </li>

          <li className={s.item}>
            <NavLink className={s.link} to={''}>
              <span className={s.span_copyright}>|</span>
            </NavLink>
          </li>

          <li className={s.item}>
            <NavLink className={s.link} to={''}>
              <span className={s.span_copyright}>© DFJW</span>
            </NavLink>
          </li>

          <li className={s.item}>
            <NavLink className={s.link} to={''}>
              <span className={s.span_copyright}>|</span>
            </NavLink>
          </li>

          <li className={s.item}>
            <NavLink className={s.link} to={''}>
              <span className={s.span_copyright}>© BMFSFJ</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </footer>
  );
};
