import React, { FC } from 'react';
import s from './Header.module.scss';
import { logo } from "../../../assets/image";

export const Header: FC = () => {
  return (
    <header className={s.header}>
      <div className={s.title_block}>
        <div className={s.wrapper}>
          <span className={s.title}>Deutsch-Französischer Entdeckungstag 2022 </span>
        </div>
      </div>

      <section className={s.logo_block}>
        <div className={s.logo_container}>
          <div className={s.logo_wrap_de}>
            {/*<img className={s.logo} alt="logo" src={logo.dfjwLogo} />*/}
            <img className={s.logo_de} alt="logo" src={logo.deutsch_flag} />
          </div>
          <div className={s.logo_wrap_fr}>
            {/*<img className={s.bmfsfj_logo} alt="BMFSFJ" src={logo.bmfsfjLogo} />*/}
            <img className={s.logo_fr} alt="BMFSFJ" src={logo.france_flag} />
          </div>

        </div>
      </section>
    </header>
  );
};

// import React, { FC } from 'react';
// import s from './Header.module.scss';
// import { logo } from '../../../assets/image';
// import { Link, NavLink } from 'react-router-dom';
// import { PATH } from '../../../routes/Routes';
//
// export const Header: FC = () => {
//   return (
//     <header className={s.header}>
//
//       <nav className={s.nav}>
//         <Link to={PATH.Home} className={s.link_logo}>
//           <img className={s.logo} alt="logo" src={logo.dfjwLogo} />
//         </Link>
//
//         <ul className={s.list}>
//
//           <li className={`${s.item}`}>
//             <NavLink to={PATH.Home} className={s.link}>
//               <span className={s.span}>Webex Meeting 1</span>
//             </NavLink>
//           </li>
//
//           <li className={`${s.item}`}>
//             <NavLink to={PATH.WebexMeeting2} className={s.link}>
//               <span className={s.span}>Webex Meeting 2</span>
//             </NavLink>
//           </li>
//
//           <li className={`${s.item}`}>
//             <NavLink to={PATH.Livestream} className={s.link}>
//               <span className={s.span}>Livestream</span>
//             </NavLink>
//           </li>
//         </ul>
//
//         <img className={s.bmfsfj_logo} alt="BMFSFJ" src={logo.bmfsfjLogo} />
//       </nav>
//     </header>
//   );
// };

