import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
// import { Webex } from '../pages/Webex/Webex';
import { Livestream } from '../pages/Livestream/Livestream';
import { WebexMeeting2 } from '../pages/WebexMeeting2/WebexMeeting2';
import {WebexMeeting1} from "../pages/WebexMeeting1/WebexMeeting1";
import { Countdown } from "../components/Timer/Countdown";
import { Impressum } from "../pages/Main/Footer/Impressum/Impressum";
import { Datenschutz } from "../pages/Main/Footer/Datenschutz/Datenschutz";

export const PATH = {
  Home: '/',
  Livestream: '/livestream/',
  WebexMeeting2: '/workshop/',
  Timer: '/timer/',
  Impressum: '/impressum/',
  Datenschutz: '/datenschutz/',
};

export const RoutesComponent: FC = () => {
  return (
    <>
      <Routes>
        <Route path={PATH.Home} element={<WebexMeeting1 />} />
        <Route path={PATH.Livestream} element={<Livestream />} />
        <Route path={PATH.WebexMeeting2} element={<WebexMeeting2 />} />
        <Route path={PATH.Impressum} element={<Impressum />} />
        <Route path={PATH.Datenschutz} element={<Datenschutz />} />
        <Route path={PATH.Timer} element={ <Countdown
          timeTillDate="10 21 2022, 12:00 pm"
          timeFormat="MM DD YYYY, h:mm a"
          // timeTillDate="10 21 2022, 6:00 am"
          // timeFormat="MM DD YYYY, h:mm a"
        />} />

        {/*<Route path="*" element={<NotFound />} />*/}
        <Route path="/" element={<WebexMeeting1 />} />
      </Routes>
    </>
  );
};
